/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-12 10:20:26
 * @Module: 创建提现申请
 */
 <template>
  <el-dialog :title="type=='add'?'添加提现申请':type=='revision'?'修改提现申请':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form" :rules="rules">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item label="提现金额" prop="amount">
              <el-input v-model="form.amount" oninput="if(value){value=value.replace(/[^\d]/g,'')}" placeholder="请输入提现金额"></el-input>
            </el-form-item>
            <el-form-item label="收款人" prop="accountName" :rules="{ required: true, message: '请输入收款人'}">
              <el-input v-model="form.accountName" placeholder="请输入收款人"></el-input>
            </el-form-item>
            <el-form-item label="收款账号开户行" prop="bank" :rules="{ required: true, message: '请输入收款账号开户行'}">
              <el-input v-model="form.bank" placeholder="例：中国建设银行股份有限公司洛阳正大国际广场支行"></el-input>
            </el-form-item>
            <el-form-item label="收款账号" prop="bankAccount" :rules="{ required: true, message: '请输入收款账号'}">
              <el-input v-model="form.bankAccount" placeholder="请输入收款账号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {
  components: {
  },
  data () {
    const validator = (_, value, callback) => {
      if (value > this.balance) {
        return callback(new Error(`提现金额不能超过余额（${this.balance}元）`));
      } else {
        return callback();
      }
    }
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        amount: null,
        accountName: null,
        bankAccount: null,
        bank: null
      },
      balance: 0,
      rules: {
        amount: [{ validator: validator, trigger: 'blur' }, { required: true, message: '请输入提现金额', trigger: 'blur' }],
      }
    };
  },
  mounted () {
    this.getPercentageProfile()
  },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        let form = this.$utils.deepClone(data);
        this.form = form
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postWithdrawApp()
        } else {
          return false
        }
      })
    },
    close () {
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        amount: null
      }
    },
    async postWithdrawApp () {
      const api = {
        revision: {
          func: this.$api.putIcase,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.postWithdrawApp,
          params: {
            ...this.form
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async getPercentageProfile () {
      const { status, data } = await this.$api.getPercentageProfile();
      if (status === 200) {
        this.balance = data.balance;
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>
 