/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-30 10:53:07
 * @Module: 分佣提现申请列表
 */
 <template>
  <div style="padding:0 16px">
    <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
      <div>分佣提现申请列表</div>
      <span v-if="['PROMOTER'].includes(user.role)">
        <el-button type="text" size="small" @click="()=>showWithdrawApplicationFrom('add')">创建提现申请</el-button>
      </span>
    </div>

    <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;" @cell-dblclick="copyText">
      <el-table-column label="提现金额" show-overflow-tooltip width="120" prop="amount">
        <template slot-scope="scope">
          <span>{{ scope.row.amount||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="会员名称" show-overflow-tooltip width="120" prop="accountName">
        <template slot-scope="scope">
          <span>{{ scope.row.promoterName||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="户名" show-overflow-tooltip width="120" prop="accountName">
        <template slot-scope="scope">
          <span>{{ scope.row.accountName||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款账号" show-overflow-tooltip width="120" prop="bankAccount">
        <template slot-scope="scope">
          <span>{{ scope.row.bankAccount||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款账号开户行" show-overflow-tooltip width="120" prop="bank">
        <template slot-scope="scope">
          <span>{{ scope.row.bank||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" show-overflow-tooltip width="120" prop="state">
        <template slot-scope="scope">
          <div v-if="scope.row.state=='CANCELED'" style="display:flex;align-items:center">
            <i class="el-icon-ali-dian1" style="color:#ccc;"></i>
            <span style="color:#666;">已撤消</span>
          </div>
          <div v-else-if="scope.row.state=='APPROVED'" style="display:flex;align-items:center">
            <i class="el-icon-ali-dian1" style="color:#0AC258;"></i>
            <span style="color:#262626;">通过</span>
          </div>
          <div v-else-if="scope.row.state=='PENDING'" style="display:flex;align-items:center">
            <i class="el-icon-ali-dian1" style="color:#0d72ff;"></i>
            <span style="color:#262626;">待审批</span>
          </div>
          <div v-else-if="scope.row.state=='DENIED'" style="display:flex;align-items:center">
            <i class="el-icon-ali-dian1" style="color:#FF0D0D;"></i>
            <span style="color:#262626;">拒绝</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" show-overflow-tooltip width="120" prop="createdAt">
        <template slot-scope="scope">
          <span v-if="scope.row.createdAt">{{
                scope.row.createdAt | timeFormat
              }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="拒绝/撤消原因" show-overflow-tooltip prop="comments">
        <template slot-scope="scope">
          <span>{{ scope.row.comments||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="110" :resizable="false" v-if="['PROMOTER','ADMIN'].includes(user.role)">
        <template slot-scope="scope">
          <span v-if="scope.row.state==='PENDING'">
            <el-button type="text" size="mini" v-if="['PROMOTER'].includes(user.role)" @click="() => showPercentageCancelFrom(scope.row)">撤销</el-button>
            <el-button type="text" size="mini" v-if="['ADMIN','SUPER'].includes(user.role)" @click="() => showPercentageProcessFrom(scope.row)">审批</el-button>
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
    <withdrawApplicationFrom @success="getWithdrawAppList" ref="withdrawApplicationFrom" />
    <percentageProcessFrom @success="getWithdrawAppList" ref="percentageProcessFrom" />
    <percentageCancelFrom @success="getWithdrawAppList" ref="percentageCancelFrom" />
    <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getWithdrawAppList" />
  </div>
</template>
 <script>
import withdrawApplicationFrom from "@/views/Commission/components/withdrawApplicationFrom"
import percentageProcessFrom from "@/views/Commission/components/percentageProcessFrom"
import percentageCancelFrom from "@/views/Commission/components/percentageCancelFrom"
import { mapState } from "vuex";
export default {
  components: {
    withdrawApplicationFrom,
    percentageProcessFrom,
    percentageCancelFrom
  },
  data () {
    return {
      list: [],
      listParams: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getWithdrawAppList()
  },
  methods: {
    async getWithdrawAppList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getWithdrawAppList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    showWithdrawApplicationFrom (type, data) {
      if (type == 'add') {
        this.$refs.withdrawApplicationFrom.show({ type })
      } else if (type == 'revision') {
        this.$refs.withdrawApplicationFrom.show({ type, data })
      }

    },
    showPercentageProcessFrom (data) {
      this.$refs.percentageProcessFrom.show({ data })
    },
    showPercentageCancelFrom (data) {
      this.$refs.percentageCancelFrom.show({ data })
    },
    copyText (row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData('text/plain', event.target.innerText);
        e.preventDefault();  //阻止默认行为
      }
      document.addEventListener('copy', save);//添加一个copy事件
      document.execCommand("copy");//执行copy方法
      this.$message({ message: '复制成功', type: 'success' })//提示
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>